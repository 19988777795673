<template>
    <meta-data-vue :Auhtor="'Martin Tiedemann'" :Description="'Står I også og overvejer hvilken type reklameskærm I skal vælge for bedst muligt at fremme jeres budskab? Så se med her, hvor vi hjælper jer videre i jagten til at finde den helt rigtige løsning'" :ImgUrl="require('@/assets/images/screens/martin.png')" :Title="'Få jeres egen reklameskærm'" />
    <div class="top-spacer"></div>
    <div class="page-wrapper">
        <TextWithSideImage 
            BackgroundColor="#f1f1f1" 
            MinHeight="20" 
            Headline="Få jeres egen <span>reklameskærm!<span>" 
            Content="<p>Vi hjælper uforbeholdent til, når kommende skærmejere skal vejledes omkring skærmløsninger fra de førende producenter på det danske marked.</p>
            <p>Vi ønsker, at udbuddet af skærme øges på det danske marked, og derfor vil enhver handel foregå direkte med skæmproducenten for at undgå mellemhandel og skabe de skarpeste priser.</p>

<p>Se det brede udvalg af skærmløsninger herunder og kontakt os for en uforpligtende pris og vejledning.</p>"
            :ImageUrl="require('@/assets/images/screens/martin.webp')"
            ImageAlt="Billede af Martin Tiedemann foran reklameskærm i Skive"
            Position="left"
        />
        <TextWithSideImage 
            BackgroundColor="white" 
            MinHeight="20"  
            Content="<p>Vi samarbejder med landets førende leverandører af skærme, og vi kan vejlede jer i den helt rette retning.</p>
                    <p>Vi kan vejlede, hvis I skal bruge<p/>
                    <ul><li>en pylon</li><li>en skærm til en facade</li><li>en solcelledrevet skærmløsning</li>
                    <li>en transportabel skærm på trailer</li>
                    <li>et komplet indendørs skærmsystem</li>
                    </ul>
                    <p>Vi kan vejlede i det hele, og det kan både arrangeres som købs-, leasing-, eller lejeløsning.</p>
                    <p>Se vores udvalg af skærmløsninger herunder.</p>"
            :ImageUrl="require('@/assets/images/screens/trailer.png')"
            ImageAlt="Servisto reklameskærm med reklameskærm.dk vist"
            Position="right"
        />
        <TextWithSideImage 
            BackgroundColor="#f1f1f1" 
            MinHeight="20"  
            Content="<h2><span>Facade</span>skærm</h2>
                    <p>Har I en bygning, som har en beliggenhed med mange forbipasserende?</p>
                    <p>I det tilfælde, er en facadeskærm måske noget for jer.</p>
                    <p>En sådan skærm kræver blot at skærmproducenten montere et beslag, føre et strømkabel og opsætter de ønskede skærmpaneler.
                        Når vi har taget en samtale omkring dette, vil vi kunne finde frem finansieringen, som tilbydes som køb, leasing eller leje.</p>"
            :ImageUrl="require('@/assets/images/screens/facade.png')"
            ImageAlt="Facadeskærm fra Servisto"
            Position="left"
        />
        <TextWithSideImage 
            BackgroundColor="white" 
            MinHeight="20"  
            Content="<h2><span>Pylon</span> med skærm</h2>
                    <p>Har I en grund, som har mange forbipasserende?</p>
                    <p>I det tilfælde, er en pylon måske noget for jer.</p>
                    <p>Prisen for en pylon varierer alt efter størrelsen på skærmen, og finansieringen tilbydes som køb, leasing eller leje.</p>
                    <p>Ønsker I at have solceller på pylonen, vil I kunne dække op til 80% af strømforbruget.</p>
                    "
            :ImageUrl="require('@/assets/images/screens/pylon.png')"
            ImageAlt="Pylon reklameskærm fra Servisto"
            Position="right"
        />
        <TextWithSideImage 
            BackgroundColor="#f1f1f1" 
            MinHeight="20"  
            Content="<h2><span>Flytbar</span>skærm</h2>
                    <p>Skal I promovere i en kort periode, eller skal I teste, om en reklameskærm er noget for jer?</p>
                    <p>Med en flytbarskærm kræver det kun, at I har adgang til en god placering og strøm.</p>
                    <p>En flytbarskærm kan lejes i den tidsperiode I ønsker, og prisen varierer herefter.</p>
                    <p>Vi anbefaler en lejeperiode på minimum én måned, da lejeprisen for én uge og én måned udgør det samme.</p>
                    "
            :ImageUrl="require('@/assets/images/screens/moveable.png')"
            ImageAlt="Flytbar reklameskærm fra Servisto"
            Position="left"
        />
        <TextWithSideImage 
            BackgroundColor="white" 
            MinHeight="20"  
            Content="<h2><span>Indendørs</span> skærmsystem</h2>
                    <p>Skal I bruge skærme indendørs, fordi I har en butik, et kontor eller et center?</p>
                    <p>Med et indendørs skærmsystem kan I have skærme, som kan køre 16 timer ad gangen og som tænder og slukker selv. Fra mobil og computer, kan I selv styre hvilke billeder og videoer, som skal vises.</p>
                    <p>Disse løsninger tilbydes kun som køb og leasing, da der ikke er vedligeholdelse forbundet med dem.</p>
                    "
            :ImageUrl="require('@/assets/images/screens/indoor.jpeg')"
            ImageAlt="Indendørs reklameskærm fra Servisto"
            Position="right"
        />
        <Textblock BackgroundColor="#f1f1f1"  Content="
            <h1>Vigtigt at vide!</h1>
            <p>Vi har samlet de få vigtige ting, der er at vide, når det kommer til køb, leasing og leje af skærm.</p>
            <ul>
                <li>
                    Ved <b>køb</b> og <b>leasing</b> af skærm, anbefaler vi at indhente en tilladelse fra den lokale myndighed.
                    Skærmproducenten hjælper jer med ansøgningen, og skulle ansøgningen blive afvist, afholdes der ingen omkostninger forbundet hermed.
                </li>
                <li>
                    Strømforbruget for nye skærmpaneler er ca. 0,2 kWh pr. m2 skærm.
                </li>
                <li>
                    Ved <b>køb og leasing</b> af skærm, er det vigtigt at have styr på ting såsom; software, internetforbindelse, forsikring, montering, kabellægning, registyrering af kabler i jord, og en evt. serviceaftale ved nedbrud.
                </li>
                <li>
                    Ved <b>køb</b> af skærm, er det vigtigt at notere, at levetiden for en skærm er ca. 5 til 10 år, hvorefter reperationer eller udskiftning af skærmdele vil skulle budgetteres for.
                </li>
            </ul>
        " />
        <TextWithSideImage 
            BackgroundColor="white" 
            MinHeight="20"  
            Content="<h2><span>Vil I have en pris?</span></h2>
                    <p>Prisen for en skærm varierer alt efter størrelsen, og finansieringen kan enten tilbydes som køb, leasing eller leje.</p>
                    <p>Men hvad er det rette for jer, og hvad er den endelige pris?</p>
                    <p>Martin vejleder jer hele vejen, og sørger for, at I får en konkret pris at forholde jer til.</p>
                    <p>T: <a href='tel:93609001'>+45 93609001</a></p>
                    <p>M: <a href='mailto:kontakt@reklameskaerm.dk'>kontakt@reklameskaerm.dk</a></p>
                    "
            :ImageUrl="require('@/assets/images/screens/martin.webp')"
            ImageAlt="Billede af Martin Tiedemann foran en af vores skærme i Skive"
            Position="right"
        />
    </div>
</template>

<script>
import Textblock from '../components/layouts/Textblock.vue';
// @ is an alias to /src
import TextWithSideImage from '../components/layouts/TextWithSideImage.vue';
import MetaDataVue from '../components/shared/MetaData.vue';

export default {
    name: 'GetYourOwnScreen',
    components: {
        TextWithSideImage,
        Textblock,
        MetaDataVue
    },
    data() {
        return {
            caseID: ''
        }
    },
    mounted() {
        this.caseID = this.$route.params.caseID;
    }
}
</script>

<style scoped>



@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      width: 100% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .top-spacer {
      height: 50px !important;
    }

    .page-wrapper h2 {
      padding-left: 20px;
    }
}

.top-spacer {
  width: 100%;
  height: 80px;
}



</style>
